<template>
  <Layout>
    <Header class="header">
      <!-- <Breadcrumb>
        <BreadcrumbItem>系统角色</BreadcrumbItem>
      </Breadcrumb> --> 
      <Button type="info" style="margin-right: 20px;background-color:#00A0E9;" class="add-btn" to="/sys_manage/role/add_role">添加</Button>
    </Header>
    <Content>
      <Table class="table-list" :loading="loading" :columns="columns" :data="data.list" no-data-text="没有符合查询条件的数据">
        <template slot-scope="{ row }" slot="date">
          {{ row.createTime | formatDate }}
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button type="info" size="small" class="action-btn" :to="'/sys_manage/role/edit_role/'+row.id">修改</Button>
          <Button type="success" size="small" class="action-btn" :to="'/sys_manage/role/join_res/'+row.id">关联资源</Button>
          <Poptip
            confirm
            transfer
            title="确定要删除该角色吗?"
            @on-ok="deleteItem(row.id)"
          >
            <Button v-if="row.id!='1'" type="warning" size="small" class="action-btn">删除</Button>
          </Poptip>
        </template>
      </Table>
      <div class="page-nav">
        <Page 
          show-total
          :total="data.total"
          @on-change="changPageNum"
        />
      </div>
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 1,
      columns: [
        {
          title: '角色名称',
          key: 'roleName',
          width: 300,
        },
        {
          title: '角色简介',
          key: 'roleDesc',
          width: 300,
        },
        {
          title: '操作',
          slot: 'action',
          // width: 400
        },
      ],
      data: {
        total: 0,
        list: [],
      },
    }
  },
  methods: {
    getData() {
      this.loading = true
      const { pageNum, pageSize } = this
      const params = { pageNum, pageSize }
      request.get('/api/sys/role/page', params)
      .then((data) => {
        this.data = data
        this.loading = false
      })
    },
    deleteItem(id) {
      request.del(`/api/sys/role/${id}`)
      .then((res) => {
        this.$Message.success('删除成功!')
        this.getData()
      })
    },
    /* 改变pageNum */
    changPageNum(num) {
      this.pageNum = num;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
})
</script>
<style scoped>
  .ivu-layout{
    background-color: transparent;
    padding: 0 10px;
  }
  ::v-deep .ivu-layout-header{
    padding: 0;
    height: 40px;
    line-height: 0;
    background-color: transparent;
  }
  
.action-btn{
  margin: 0 5px;
}
.ivu-table-wrapper {
    position: relative;
    border: none;
    border-right: 0;
    overflow: hidden;
}
::v-deep .ivu-table{
    background: transparent;
    color: #fff;
    font-size: 16px;
}
::v-deep .ivu-table:before{
    background-color: transparent;
}
::v-deep .ivu-table th{
    background: #fff;
    border-color: #00A0E9;
}
::v-deep .ivu-table-header{
    border-radius: 8px;
}
::v-deep .ivu-table-header thead tr th {
    background: #00A0E9;
    border-color: #00A0E9;
    color: #fff;
}
::v-deep .ivu-table td {
    background: transparent;
    border-color: #00A0E9;
}
::v-deep .ivu-table-cell {
    padding-right: 0;
    padding-left: 13px;
    display: block;
    text-align: center;
}
/* 分页 */
.page-nav {
    color: #fff;
    margin-top: 20px;
}
::v-deep .ivu-page-prev,::v-deep .ivu-page-next {
    background: transparent;
    border: 1px solid #0077EE;
}
::v-deep .ivu-page-item{
    background: transparent;
    border: 1px solid #0077EE;
}
::v-deep .ivu-page-item a{
    color: #fff;
}
::v-deep .ivu-page-item-active a{
    color: #0077EE;
}
/* 滚动 + 加载中 */
::v-deep .ivu-table-overflowY::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #0077EE;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 1px solid #0077EE;
}
::v-deep .ivu-spin-fix{
    background-color: transparent;
}
::v-deep .ivu-table-wrapper>.ivu-spin-fix{
    border: none;
}
</style>
